import React, { ReactElement, FC } from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/Layout/Layout";
import { PartialPost, PartialShowcase } from "../types/types";
import { Callout } from "../components/Callout/Callout";
import { PostCard } from "../components/Cards/PostCard";
import { SEO } from "../components/SEO/SEO";
import { PageHeader } from "../components/PageHeader/PageHeader";
import { CardCarousel } from "../components/CardCarousel/CardCarousel";
import { CardList } from "../components/CardList/CardList";

type ShowcasePageProps = PageProps<{
	allPrismicPost: {
		nodes: PartialPost[];
	};
	allPrismicShowcase: {
		nodes: PartialShowcase[];
	};
}>;

const ShowcasePage: FC<ShowcasePageProps> = ({
	data: {
		allPrismicPost: { nodes: resources },
		allPrismicShowcase: { nodes: showcases },
	},
}): ReactElement => {
	const title = "See what you can build!";
	const subtitle =
		"Not sure what to create? Need some inspiration? Take a look through some games built by other Adeptly creators.";
	const seoTitle = null;
	const seoDescription = null;

	return (
		<Layout>
			<SEO title={seoTitle || title} description={seoDescription || subtitle} />
			<PageHeader heading={title} lead={subtitle} />

			<CardList ariaLabel="Game showcases" cards={showcases} type="showcase" />

			<Callout />

			<CardCarousel
				heading="It's so simple to make your own&nbsp;games"
				cta="See all resources"
				ctaLink="/resource-centre/"
			>
				{resources.map((item) => {
					return <PostCard content={item} key={item.uid} />;
				})}
			</CardCarousel>
		</Layout>
	);
};

export default ShowcasePage;

export const pageQuery = graphql`
	{
		allPrismicShowcase(
			sort: {
				fields: [data___is_featured, first_publication_date]
				order: [ASC, DESC]
			}
		) {
			nodes {
				...PartialShowcase
			}
		}
		allPrismicPost(
			filter: { data: { is_featured: { eq: true } } }
			limit: 3
			sort: { fields: first_publication_date, order: DESC }
		) {
			nodes {
				...PartialPost
			}
		}
	}
`;
