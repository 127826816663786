import React, { FC } from "react";
import { PartialPost, PartialShowcase } from "../../types/types";
import { Container } from "../Container/Container";
import { PostCard } from "../Cards/PostCard";
import { ShowcaseCard } from "../Cards/ShowcaseCard";
import styles from "./CardList.module.scss";

export interface CardListProps {
	ariaLabel: string;
	// TODO Y U NOT WERK?
	cards: PartialPost[] | PartialShowcase[];
	type: "showcase" | "resource";
}

export const CardList: FC<CardListProps> = ({ ariaLabel, cards, type }) => {
	return (
		<Container>
			<ol className={styles.cardList} aria-label={ariaLabel}>
				{cards.map((card, index) => (
					<li className={styles.cardListItem} key={index}>
						{type === "showcase" ? (
							<ShowcaseCard content={card as PartialShowcase} />
						) : (
							<PostCard content={card as PartialPost} />
						)}
					</li>
				))}
			</ol>
		</Container>
	);
};

export default CardList;
