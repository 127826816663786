import React, { FC } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import classnames from "classnames";
import { PartialPost } from "../../types/types";
import styles from "./Cards.module.scss";

export interface PostCardProps {
	content: PartialPost;
	/** Add a 'featured' flag to the top left of the image */
	isFeatured?: boolean;
	/** This will determine the 'featured' text e.g. "featured article" */
	type?: string;
	/** Text for the cta at the bottom of the card */
	cta?: string;
}

export const PostCard: FC<PostCardProps> = ({
	content: {
		uid,
		first_publication_date,
		first_publication_date_formatted,
		data: { title, image, summary, author, is_featured },
	},
}) => (
	<article className={styles.card}>
		<div className={styles.imageContainer}>
			<GatsbyImage
				className={styles.image}
				image={image.fluid}
				alt={image.alt || title}
			/>
			{is_featured && (
				<span className={classnames([styles.featuredFlag, styles.article])}>
					Featured resource
				</span>
			)}
		</div>
		<div className={styles.text}>
			<h2 className={styles.heading}>
				<Link to={`/resource-centre/${uid}`} className={styles.link}>
					{title}
				</Link>
			</h2>
			<p className={styles.author}>By {author.document.data.name}</p>

			<time className={styles.date} dateTime={first_publication_date}>
				{first_publication_date_formatted}
			</time>
			<p className={styles.summary}>{summary}</p>
			<p>
				<Link to={`/resource-centre/${uid}`} tabIndex={-1}>
					Read article
					<span className="visuallyHidden">: {title}</span>
				</Link>
			</p>
		</div>
	</article>
);
