import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./PageHeader.module.scss";
import { Container } from "../Container/Container";

export interface PageHeaderProps {
	heading: ReactNode;
	lead?: ReactNode;
	isDark?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({ heading, lead, isDark }) => (
	<div className={classNames(styles.wrapper, { [styles.dark]: isDark })}>
		<Container>
			<h1 className={styles.heading}>{heading}</h1>
			{lead && <p className={styles.lead}>{lead}</p>}
		</Container>
	</div>
);
